<template>
  <div class="rating d-flex justify-content-center align-items center">
     <b-form-rating size="lg" inline  :stars="options.length" no-border v-model="selectedRating" variant="warning" class="mb-2"></b-form-rating>
  </div>
</template>

<script>
export default {
  props: {
    options: Array // Prop for options data containing values and texts for labels
  },
  data(){
    return {

        selectedRating:0
    }
  },
  watch:{
    selectedRating(newValue){
        this.$emit('updatedOption',this.options[newValue-1])
    }
  },
  methods: {
    getLabelColor(value) {
      if (value <= this.selectedOption) {
        return { color: "#ffa723" }; // Change color for checked and previous labels
      } else {
        return { color: "#666" }; // Default color for unchecked labels
      }
    }
  }
};
</script>

<style scoped>
/* Your existing CSS for rating */
.rating:not(:checked) > span > input {
  position: absolute;
  appearance: none;
  opacity: 0;
}

/* Additional styling for checked and unchecked labels */
.rating:not(:checked) > span > label {
  float: right;
  cursor: pointer;
  font-size: 30px;
}

.rating:not(:checked) > span > label:before {
  content: '★';
}

/* Styling for checked labels */
.rating > span > input:checked + label:hover,
.rating > span > input:checked + label:hover ~ label,
.rating > span > input:checked ~ label:hover,
.rating > span > input:checked ~ label:hover ~ label,
.rating > span > input:checked ~ label:hover ~ label {
  color: #e58e09;
}

/* Styling for unchecked labels */
.rating:not(:checked) > span > label:hover,
.rating:not(:checked) > span > label:hover ~ label {
  color: #ff9e0b;
}

/* Styling for checked labels' color */
.rating > span > input:checked ~ label {
  color: #ffa723;
}
</style>
